import React from 'react';

const FreeGames = () => {
  const handleClick = (gamesUrl) => {
    window.location.href = gamesUrl;
  };

  const games = [
    { thumbnail: 'Great_Air_Battles.webp', url: 'https://cdn-content.mmsdp.com/newgames127/unzipped%20games/045.Great%20Air%20Battles/' },
    { thumbnail: 'HexGL.webp', url: 'https://cdn-content.mmsdp.com/HexGL-master/' },
    { thumbnail: 'Stick-Soldier.webp', url: 'https://cdn-content.mmsdp.com/newgames127/unzipped%20games/047.Stick%20Soldier/' },
  ];

  return (
    <div>
      <section className="game-play-section game-play-section2" style={{ minHeight: '300px' }}>
        <h1 className='CETEGORIES' style={{ fontSize: '1.5em' }}>FREE FOR YOU</h1>
        <div className="games-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
          {games.map((game, index) => (
            <div key={index} className="single-play1 action-games" style={{ marginBottom: '20px' }}>
              <div className="image" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '14px' }}>
                <img src={`assets/Icons/${game.thumbnail}`} alt="action-game-images" className="zoomable-image free-images" loading="lazy" />
              </div>
              <div className="play-like-action" style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <a
                  className='like-button'
                  style={{ display: 'flex', justifyContent: 'center', fontSize: '18px', width: '100px', letterSpacing: '1px' }}
                  onClick={() => handleClick(game.url)}
                >
                  Play
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FreeGames;
