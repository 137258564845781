import React from "react";

const HomeFooter = () => {
  return (
    <footer class="footer" id="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="flogo">
              <a href="#">
                <img
                  id="logoImage"
                  src="assets/images/footer-ogo.png"
                  alt="logo"
                />
              </a>
            </div>
            <div class="social-links"></div>
            <div class="footer-menu"></div>
          </div>
        </div>
      </div>
      <div class="copy-bg">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <p>Copyright © 2024. All Rights Reserved By Gamez</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default HomeFooter;
