import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

const LoginAndOtp = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState({ otp1: '', otp2: '', otp3: '', otp4: '' });
  const inputRefs = useRef([]);

  const handleOtpChange = (e) => {
    const { name, value } = e.target;
    const nextInputIndex = parseInt(name.replace('otp', ''));
    if (/^\d*$/.test(value) && value.length <= 1) {
      setOtp({ ...otp, [name]: value });

      // Move to next input if a value is entered
      if (value && nextInputIndex < 4) {
        inputRefs.current[nextInputIndex].focus();
      }
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 11) {
      setPhoneNumber(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fullOtp = otp.otp1 + otp.otp2 + otp.otp3 + otp.otp4;
    // Handle OTP submission logic here
    console.log("Entered OTP:", fullOtp);
  };

  const handleKeyDown = (e) => {
    const { name, value } = e.target;
    const currentInputIndex = parseInt(name.replace('otp', '')) - 1;

    if (e.key === 'Backspace' && value === '' && currentInputIndex > 0) {
      inputRefs.current[currentInputIndex - 1].focus();
    }
  };

  const isOtpComplete = otp.otp1 && otp.otp2 && otp.otp3 && otp.otp4;

  return (
    <div className="login-otp-container">
      <div className="login-otp-content">
        <img src="assets/images/logo.png" alt="Game Now Logo" className="login-otp-logo" />
        <h1>Welcome To Game Now</h1>
        <form onSubmit={handleSubmit} className='login-otp-form'>
          <label htmlFor="phoneNumber">Enter your Zong Number</label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            className='phone-number'
            placeholder='03128648637'
            maxLength="11"
          />
          <p>We have sent the OTP to the above number</p>
          <div className="otp-inputs">
            {[...Array(4)].map((_, index) => (
              <input
                key={index}
                type="text"
                name={`otp${index + 1}`}
                value={otp[`otp${index + 1}`]}
                onChange={handleOtpChange}
                onKeyDown={handleKeyDown}
                maxLength="1"
                className="otp-input"
                ref={(el) => (inputRefs.current[index] = el)}
                disabled={phoneNumber.length !== 11}
              />
            ))}
          </div>
          <button className='resend-otp'>{isOtpComplete && 'Resend OTP'}</button>
          <button type="submit" className="continue-btn">
            {isOtpComplete ? 'Subscribe' : 'Continue'}
          </button>
        </form>
        <p className="info-text">
          Only For Zong Prepaid Customers<br />
          Low balance users can enjoy 1 day free.<br />
          Pay only Rs.20+tax/week
        </p>
        <Link to="/policy" className="terms-link">Terms and conditions</Link>
      </div>
    </div>
  );
};

export default LoginAndOtp;
