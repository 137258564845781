var inputName = '';
var text ='';
var heCase = false;
var vInfo = [];
var trackerId = '';
var msisdn = '';
var userToken = '';
var gamesUrl = 'https://cdn.mmsdp.com/';
var gamesThumbnailName = '';

export const setUserToken = (t) => {
  userToken = t;
}

export const getUserToken = () => {
  return userToken;
}

export const setMsisdn = (m) => {
  msisdn = m;
}

export const getMsisdn = () => {
  return msisdn;
}

export const setTrackerId = (t) => {
  trackerId = t;
}

export const getTrackerId = () => {
  return trackerId;
}

export const setText = (t) => {
  text = t;
}

export const getText = () => {
  return text;
}

export const setInputName = (name) => {
  inputName = name;
};

export const getInputName = () => {
  return inputName;
};
export const getHeCase = () => {
  return heCase;
}
export const setHeCase = (he) => {
  heCase=he;
}
export const setVInfo = (v) => {
  vInfo = v;
}
export const getVInfo = () => {
  return vInfo;
}
export const setGamesUrl = (url) => {
  gamesUrl = url;
}
export const getGamesUrl = () => {
  return gamesUrl;
}
export const setGamesThumbnailName = (name) => {
  gamesThumbnailName = name;
}
export const getGamesThumbnailName = () => {
  return gamesThumbnailName;
}
