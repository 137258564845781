import { myAxios } from "./helper"

var headers = { "Content-Type": "application/x-www-form-urlencoded" };
export const clientLogin = (Basic) => {
    headers = { ...headers, Authorization: 'Basic ' + Basic };
    let url = "https://dcb.noeticworld.com/sgw/authorize?grant_type=client_credentials";
    return myAxios.post(url, '', { headers }).then((response) => response.data);

}

export const login = (body) => {
    headers = {
        ...headers,
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
    };
    let url = "https://dcb.noeticworld.com/sgw/user/login";
    return myAxios.post(url, body, { headers }).then((response) => response.data);
}

export const checkUser = (msisdn) => {
    let url = "https://score.mmsdp.com/api/user-id";

    return myAxios.post(url, msisdn).then((response) => response.data);
}

export const getUserByMsisdn = (msisdn) => {
    let url = "https://score.mmsdp.com/api/user-entity";
    return myAxios.post(url, msisdn).then((response) => response.data);
}


export const AddUser = (user) => {
    let url = "https://score.mmsdp.com/api/store-users";
    return myAxios.post(url, user).then((response) => response.data);
}

export const userSession = (Session) => {
    let url = "https://score.mmsdp.com/api/store-sessions";
    return myAxios.post(url, Session).then((response) => response.data);
}

export const userSessionID = () => {
    let url = "https://score.mmsdp.com/api/max-user-session-id";
    return myAxios.get(url).then((response) => response.data);
}

export const requestStatus = (correlationId) => {

    headers = {
        ...headers,
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
    };
    let url = "https://dcb.noeticworld.com/sgw/user/requestStatus/" + correlationId;
    return myAxios.get(url, { headers }).then((response) => response.data);
}

export const otpVerification = (body) => {
    headers = {
        ...headers,
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
    };
    let url = "https://dcb.noeticworld.com/sgw/user/otp-verification/";
    return myAxios.post(url, body, { headers }).then((response) => response.data);
}

export const unsubscribe = (msisdn) => {
    headers = {
        ...headers,
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
    };
    const body = `msisdn=${msisdn}&otp=false`;
    let url = "https://dcb.noeticworld.com/sgw/user/unsubscribe/";
    return myAxios.post(url, body, { headers }).then((response) => response.data);
}

export const fetchOtp = (msisdn) => {
    headers = {
        ...headers,
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
    };
    let url = "https://dcb.noeticworld.com/sgw/user/otp-verification/fetchOtp?msisdn=" + msisdn;
    return myAxios.post(url, '', { headers }).then((response) => response.data);
}

export const HECalling = () => {
    headers = {
        ...headers,
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
    };
    let body = {
        'serviceId': 'ps-so-pk-zg-22'
    }
    let url = "https://dcb.noeticworld.com/sgw/user/he";
    return myAxios.post(url, body, { headers }).then((response) => response.data);
}



export const Register = (body) => {
    headers = {
        ...headers,
        "Content-Type": "application/json",
        token: 'zong@1122'
    };
    let url = "https://gamezplay.noeticworld.com/api/users/register";
    return myAxios.post(url, body, { headers }).then((response) => response.data);
}

export const checkZongChargedUser = (msisdn) => {
    headers = {
        ...headers,
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
    };
    let url = "https://dcb.noeticworld.com/sgw/user/otp-verification/is-zong-msisdn-subscribed/" + msisdn;
    return myAxios.get(url, { headers }).then((response) => response.data);
}


export const Login = (token) => {
    let url = "https://gamezplay.noeticworld.com/api/users/login/" + token;
    return myAxios.get(url, { withCredentials: true }).then((response) => response.data);
}

export const GetMsisdn = (vinfo) => {
    let url = "http://210.56.27.20:8080/get_jazz_headers?vinfo=" + vinfo;
    return myAxios.get(url).then((response) => response.data);
}

export const HeCalling = (vinfo) => {
    let url = "https://zonghe.ntgarage.com/test2?Path=get_test_zong_origin?vinfo=" + vinfo;

    return myAxios.get(url).then((response) => response.data);
}

