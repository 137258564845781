import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { games } from '../components/AllGames';

const AuthenticatedGamesHeader = ({ onJoinUsClick }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredGames, setFilteredGames] = useState([]);

  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear localStorage
    localStorage.clear();
    // Reload the React app
    window.location.reload();
  };

  const handleClick = () => {
    // Simulating fetchHe function to get msisdn and update input fields
    const response = { msisdn: '1234567890' }; // This is just a mock response
    const msisdn = response.msisdn;
    const modifiedMsisdn = '0' + msisdn.substring(2);
    // document.getElementById('input-name').value = modifiedMsisdn;
    // document.getElementById('otp-field').value = modifiedMsisdn;
    onJoinUsClick();
    navigate('/profile-view');
  };

  const handleGameClick = (gamesThumbnail, gamesUrl) => {
    localStorage.setItem('gameThumbnail', gamesThumbnail);
    localStorage.setItem('gameUrl', gamesUrl);
    navigate('../game-component1');
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query) {
      const filtered = games.filter((game) =>
        game.thumbnail.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredGames(filtered);
    } else {
      setFilteredGames([]);
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // Perform any additional actions on search submit if needed
  };

  return (
    <header className="header">
      <div className="overlay"></div>
      <section className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="content"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="mainmenu-area">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: '#1f0644' }}>
                <Link className="navbar-brand" to="/home" aria-label='Logo'>
                  <img src="assets/images/logo.png" alt="logo" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#main_menu"
                  aria-controls="main_menu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse fixed-height" id="main_menu">
                  <ul className="navbar-nav mr-auto justify-content-center text-center">
                    <li className="nav-item">
                      <Link className="nav-link" to="/home">Home</Link>
                    </li>
                    <li className="nav-item text-center">
                      <Link className="nav-link" to="/games-all">All Games</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/favorites">Favorite Games ❤️</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/downloadnow">Download Now</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/unsubscribe">Unsubscribe</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" onClick={handleLogout}>Logout</Link>
                    </li>
					<li>
					<form className="form-inline my-2 my-lg-0 position-relative" onSubmit={handleSearchSubmit}>
                    <input
                      className="form-control mr-sm-2 search-box"
                      type="search"
                      placeholder="Search all games"
                      aria-label="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    {searchQuery && (
                      <div className="search-results-dropdown">
                        {filteredGames.length > 0 ? (
                          filteredGames.map((game) => (
                            <div key={game.id} className="game-card" onClick={() => handleGameClick(`assets/Icons/${game.thumbnail}`, game.url)}>
                              <img src={`assets/Icons/${game.thumbnail}`} alt={game.name} className="img-fluid" />
                              <h5>{game.name}</h5>
                            </div>
                          ))
                        ) : (
                          <p>No games found.</p>
                        )}
                      </div>
                    )}
                  </form>
					</li>
                    <li className="nav-item">
                      <a href="#" className="myavatar" data-toggle="modal" data-target="#signin" onClick={handleClick}>
                        <img src="assets/images/avatar-1.webp" alt="Join NOW" />
                      </a>
                    </li>
                  </ul>
                  
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AuthenticatedGamesHeader;
