import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { fetchHe } from '../services/fetch_he';
import { userSessionID, userSession } from '../services/client-login-service';
import { useNavigate } from 'react-router-dom';
import { setMsisdn, setText } from '../utils/global';
import FreeGames from '../components/FreeGames';


const HomeBody = () => {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const handleClick = () => {
        fetchHe().then((response) => {
            const msisdn = response.msisdn;
            const modifiedMsisdn = "0" + msisdn.substring(2);
            setMsisdn(modifiedMsisdn);
        }).catch((error) => {
            // Handle error if needed
        });
        navigate("index.php");
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const gtag = window.gtag || function () {};
                const conversion = () => {
                    gtag('event', 'conversion', {
                        'send_to': 'AW-10822961199/WTsnCK79nIkDEK-Q5ago'
                    });
                };

                conversion();

                const eventCategory = 'play_now_button';
                const eventAction = 'clicked';

                // Send the GA event using the gtag function
                window.gtag('event', eventAction, {
                    'event_category': eventCategory
                });

                const userSessionIDValue = await userSessionID();
                localStorage.setItem("UserSessionId", userSessionIDValue + 1);

                const session = {
                    'userSessionId': localStorage.getItem('UserSessionId'),
                    'sessionType': 'Home'
                };

                await userSession(session);

                // Update loading state when all asynchronous operations are completed
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                // Handle error if needed
            }
        };

        fetchData();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    const totalImageCount = 3;
    const [currentImageIndex, setCurrentImageIndex] = useState(1);

    const firstImages=<img src="assets/images/coins.webp" alt="" style={{height:'50px', width:'30px'}}/>
	const secondImage=<img src="assets/images/coins.webp" alt="" style={{height:'50px', width:'30px',marginLeft:'1px'}}/>
	const thirdImage=<img src="assets/images/coins.webp" alt="" style={{height:'50px', width:'30px',marginLeft:'1px'}}/>

    const handleDotClick = (index) => {
        setCurrentImageIndex(index);
    };

    return (
        <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        <div>
            <div class="hero-area carousel-background">
                {/* <img class="shape parallax5" src="assets/images/home/h2-shape.webp" alt=""/> */}
                <div class="carousel-container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="content">
                                <div class="content">
                                    <h5 class="subtitle">
                                        PLAY NOW
                                    </h5>
                                    <h1 class="title">
                                    <img
                                            src="assets/images/text.webp"
                                            alt=""
                                            style={{ marginRight: '-12px' }}
                                            // loading="lazy" 
                                            className='hero-area-text-image'
                                        />
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="game-play-section">
                <img class="left-img" src="assets/images/game-play/left-img.webp" alt=""  loading="lazy" />
                <div class="container">
                    <div class="row ">
                        <div class="col-lg-12">
                            <div class="section-heading">
                                <h5 class="subtitle">
                                    The Games You Love
                                </h5>
                                <h2 class="title ">
                                    PLAY AND WIN
                                </h2>
                                <h6 class="text">
                                    Join millions of Zong players in Pakistan.
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-xl-3 col-lg-4 col-md-6 landing-page-game" onClick={handleClick}>
                            <div class="single-play landing-page-game-contain">
                                <div class="image" style={{marginTop:'6px'}}>
                                <img
                                        src="assets/images/thumbnail16.webp"
                                        alt=""
                                        className="zoomable-image"
                                        loading="lazy" 
                                    />
                                </div>
                                <div class="contant" style={{justifyContent:'center', display:'flex'}}>
                                    <span class="mybtn1 pulse-button" style={{alignItems:'center', display:'flex', justifyContent:'center'}}>Play</span>

                                </div>
                            </div>


                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 landing-page-game" onClick={handleClick}>
                            <div class="single-play landing-page-game-contain">
                                <div class="image" style={{marginTop:'6px'}}>
                                    <img src="assets/images/thumbnail7.webp" alt="" className="zoomable-image"  loading="lazy" />
                                </div>
                                <div class="contant" style={{justifyContent:'center', display:'flex'}}>
                                    <span class="mybtn1 pulse-button" style={{alignItems:'center', display:'flex', justifyContent:'center'}}>Play</span>

                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 landing-page-game" onClick={handleClick}>
                            <div class="single-play landing-page-game-contain">
                                <div class="image" style={{marginTop:'6px'}}>
                                    <img src="assets/images/thumbnail3.webp" alt="" className="zoomable-image"  loading="lazy" />
                                </div>
                                <div class="contant" style={{justifyContent:'center', display:'flex'}}>
                                    <span class="mybtn1 pulse-button" style={{alignItems:'center', display:'flex', justifyContent:'center'}}>Play</span>

                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 landing-page-game" onClick={handleClick}>
                            <div class="single-play landing-page-game-contain">
                                <div class="image" style={{marginTop:'6px'}}>
                                    <img src="assets/images/thumbnail4.webp" alt="" className="zoomable-image"  loading="lazy" />
                                </div>
                                <div class="contant" style={{justifyContent:'center', display:'flex'}}>
                                    <span class="mybtn1 pulse-button" style={{alignItems:'center', display:'flex', justifyContent:'center'}}>Play</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
				<FreeGames/>
			</section>
            <section className="latest-arcive">
                <div className="container">
                    <div className="d-flex justify-content-center"> {/* Use 'd-flex' class for flexbox */}
                        <div className="col-lg-6">
                            
                            <div className="sh-wrpper" style={{justifyContent:'center' , display: 'flex' , margin:'0px'}}>
                                <img className="mx-auto d-block" style={{margin:'0px'}} src="assets/images/arcive/i1.webp" alt=""  loading="lazy" />
                                <div className="section-heading">
                                
                                    <h2 className="title">Leaderboard</h2>
                                    
                                </div>
                                <div>

                                </div>
                            </div>
                            <Slider {...settings}>
								<div className="l-arcive-box">
									<h5 className="subtitle" style={{ justifyContent: 'center', display: 'flex' }}>Brick-Breaker-Unicorn</h5>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position1.webp" alt="" />
											<h6>Imran</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>7325 Coins</h6>
											<div style={{display:'flex'}}>
												{firstImages} {secondImage}{thirdImage}
											</div>
										</div>
									</div>
									<div className="s-a-b" >
										<div className="left">
											<img src="assets/images/position 2.webp" alt="" />
											<h6>Alwi</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>5745 Coins</h6>
											<div style={{display:'flex'}}>
												{secondImage}{thirdImage}
											</div>
										</div>
									</div>
									<div className="s-a-b" >
										<div className="left">
											<img src="assets/images/position 3.webp" alt="" />
											<h6>Omair</h6>
										</div>
										<div className="right" style={{marginLeft:'15px',marginTop:'30px'}}>
											<h6>2130 Coins</h6>
											<div style={{display:'flex'}}>
												{thirdImage}
											</div>
										</div>
									</div>
								</div>
								<div className="l-arcive-box">
									<h5 className="subtitle" style={{ justifyContent: 'center', display: 'flex' }}>Halloween Bubble Shooter</h5>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position1.webp" alt="" />
											<h6>Nishat</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>4765 Coins</h6>
											{firstImages} {secondImage}{thirdImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 2.webp" alt="" />
											<h6>Sajjad</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>3512 Coins</h6>
											{firstImages} {secondImage}
										</div>
									</div>
									<div className="s-a-b" >
										<div className="left">
											<img src="assets/images/position 3.webp" alt="" />
											<h6>Altaf</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>1208 Coins</h6>
											{firstImages}
										</div>
									</div>
								</div>
								<div className="l-arcive-box">
									<h5 className="subtitle" style={{ justifyContent: 'center', display: 'flex' }}>Handless Millionaire</h5>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position1.webp" alt="" />
											<h6>Adil</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>9427 Coins</h6>
											<div style={{display:'flex'}}>
												{firstImages} {secondImage}{thirdImage}
											</div>
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 2.webp" alt="" />
											<h6>Rukhsar</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>6374 Coins</h6>
											<div style={{display:'flex'}}>
												{firstImages} {secondImage}
											</div>
										</div>
									</div>
									<div className="s-a-b" >
										<div className="left">
											<img src="assets/images/position 3.webp" alt="" />
											<h6>Junaid</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>5392 Coins</h6>
											<div style={{display:'flex'}}>
												{firstImages}
											</div>
										</div>
									</div>
								</div>
								<div className="l-arcive-box">
									<h5 className="subtitle" style={{ justifyContent: 'center', display: 'flex' }}>Pops-Billiards</h5>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position1.webp" alt="" />
											<h6>Jahanzeb</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>4085 Coins</h6>
											{firstImages} {secondImage}{thirdImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 2.webp" alt="" />
											<h6>Jamil</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>2629 Coins</h6>
											{firstImages} {secondImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 3.webp" alt="" />
											<h6>Laiba</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>1848 Coins</h6>
											{firstImages}
										</div>
									</div>
								</div>
								<div className="l-arcive-box">
									<h5 className="subtitle" style={{ justifyContent: 'center', display: 'flex' }}>Pro-Billiards</h5>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position1.webp" alt="" />
											<h6>Khalil</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>5429 Coins</h6>
											{firstImages} {secondImage}{thirdImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 2.webp" alt="" />
											<h6>Sohail</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>4637 Coins</h6>
											{firstImages} {secondImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 3.webp" alt="" />
											<h6>Suleman</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>1913 Coins</h6>
											{firstImages}
										</div>
									</div>
								</div>
								<div className="l-arcive-box">
									<h5 className="subtitle" style={{ justifyContent: 'center', display: 'flex' }}>Professor-Bubble</h5>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position1.webp" alt="" />
											<h6>Rohail</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>4593 Coins</h6>
											{firstImages} {secondImage}{thirdImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 2.webp" alt="" />
											<h6>Tahir</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>2416 Coins</h6>
											{firstImages} {secondImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 3.webp" alt="" />
											<h6>Kashif</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>1907 Coins</h6>
											{firstImages} 
										</div>
									</div>
								</div>
								<div className="l-arcive-box">
									<h5 className="subtitle" style={{ justifyContent: 'center', display: 'flex' }}>Jetpack-Heroes</h5>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position1.webp" alt="" />
											<h6>Rizwan</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>5413 Coins</h6>
											{firstImages} {secondImage}{thirdImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 2.webp" alt="" />
											<h6>Hussain</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>2326 Coins</h6>
											{firstImages} {secondImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 3.webp" alt="" />
											<h6>Raheel</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>1374 Coins</h6>
											{firstImages}
										</div>
									</div>
								</div>
								<div className="l-arcive-box">
									<h5 className="subtitle" style={{ justifyContent: 'center', display: 'flex' }}>Mad-Fish</h5>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position1.webp" alt="" />
											<h6>Haris</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>5058 Coins</h6>
											{firstImages} {secondImage}{thirdImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 2.webp" alt="" />
											<h6>Qasim</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>2973 Coins</h6>
											{firstImages} {secondImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 3.webp" alt="" />
											<h6>Rayyan</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>1347 Coins</h6>
											{firstImages} 
										</div>
									</div>
								</div>
								<div className="l-arcive-box">
									<h5 className="subtitle" style={{ justifyContent: 'center', display: 'flex' }}>Road-Racer</h5>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position1.webp" alt="" />
											<h6>Dilawar</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>7985 Coins</h6>
											{firstImages} {secondImage}{thirdImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 2.webp" alt="" />
											<h6>Asad</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>6454 Coins</h6>
											{firstImages} {secondImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 3.webp" alt="" />
											<h6>Anjum</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>4485 Coins</h6>
											{firstImages}
										</div>
									</div>
								</div>
								<div className="l-arcive-box">
									<h5 className="subtitle" style={{ justifyContent: 'center', display: 'flex' }}>Robotex</h5>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position1.webp" alt="" />
											<h6>Ali</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>2473 Coins</h6>
											{firstImages} {secondImage}{thirdImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 2.webp" alt="" />
											<h6>Atif</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>1566 Coins</h6>
											{firstImages} {secondImage}
										</div>
									</div>
									<div className="s-a-b">
										<div className="left">
											<img src="assets/images/position 3.webp" alt="" />
											<h6>Raees</h6>
										</div>
										<div className="right" style={{marginTop:'30px'}}>
											<h6>1283 Coins</h6>
											{firstImages} 
										</div>
									</div>
								</div>
												<div className="l-arcive-box">
													<h5 className="subtitle" style={{ justifyContent: 'center', display: 'flex' }}>Stick
														Monkey</h5>
													<div className="s-a-b">
														<div className="left">
															<img src="assets/images/position1.webp" alt="" />
															<h6>Raza</h6>
														</div>
														<div className="right" style={{marginTop:'30px'}}>
															<h6>5498 Coins</h6>
															{firstImages} {secondImage}{thirdImage}
														</div>
													</div>
													<div className="s-a-b">
														<div className="left">
															<img src="assets/images/position 2.webp" alt="" />
															<h6>Sufyan</h6>
														</div>

														<div className="right" style={{marginTop:'30px'}}>
															<h6>2074 Coins</h6>
															{firstImages} {secondImage}
														</div>
													</div>
													<div className="s-a-b">
														<div className="left">
															<img src="assets/images/position 3.webp" alt="" />
															<h6>Hassan</h6>
														</div>
														<div className="right" style={{marginTop:'30px'}}>
															<h6>1453 Coins</h6>
															{firstImages} 
														</div>
													</div>
												</div>
							</Slider>
                        </div>
                    </div>
                </div>
            </section>


            <section className='join-us-section'>
                <div className='join-section-image'>
                    <img src="assets/images/main man.webp" alt="Join section Image" />
                </div>
                <div className="join-section-info">
                    <h5 className='join-section-h5'>Lots of winners everyday</h5>
                    <h2 className='join-section-h2'>Be one of them</h2>
                    <h6 className='join-section-h6'>Play your favourite ad free games with GAMENOW and beat the scoreboard </h6>
                </div>
            </section>


        </div>
        </div>

    );
};

export const onPlayClick = (msisdn) => {
    HomeBody.handleOnPlayClick(msisdn);
}

export default HomeBody;
