import React from 'react'
import LoginAndOtp from '../components/LoginAndOtp'

const LoginOtp = () => {
  return (
    <>
    <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="forntEnd-Developer" content="Saad & Muzammil" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>GameNow</title>
        <link rel="shortcut icon" href="assets/images/favicon.png" type="image/x-icon" />
        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/plugin.css" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <link rel="stylesheet" href="assets/css/responsive.css" />
      </head>
      <body>
        <LoginAndOtp/>
        </body>
        </html>
    </>
  )
}

export default LoginOtp