import React,{useState} from 'react';
import { fetchHe } from '../services/fetch_he';
import { setMsisdn, setText } from '../utils/global'; 
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const HomeHead = () => {
	const navigate = useNavigate();
  
	const handleClick = () => {
		ReactGA.event({'category': 'LandingPage', 'action':'submit', 'label':'label'});
		fetchHe().then((response) => {
			const msisdn = response.msisdn;
			const modifiedMsisdn = "0" + msisdn.substring(2);
			setMsisdn(modifiedMsisdn);
		}).catch((error) => {
			
		  });
		  navigate("index.php");
	  };
			
	
  return (
		<header class="header">
			<div class="overlay"></div>
			<section class="top-header">
				<div class="container">
					<div class="row">
					</div>
				</div>
			</section>
			<div class="mainmenu-area">
				<div class="container">
					<div class="row">
						<div class="col-lg-12" 
						>                 
							<nav class="navbar navbar-expand-lg navbar-light" style={{
							height:'90px',
							
						  }}>
								<a class="navbar-brand" href="index.php">
									<img src="assets/images/logo.png" alt=""/>
								</a>

								<span class="mybtn10 join-now-button" style={{ position: 'fixed', right: 0, marginRight:'10px' }} data-toggle="modal"  onClick={handleClick}> Join NOW</span>
							</nav>
						</div>
					</div>
				</div>
			</div>
			
		</header>
	  );
	 
};

export default HomeHead;