import React from "react";
import "./WhatsAppButton.css"; // Optional: For styling the button
import whatsappicon from "../Assets/whatsapp.png";

const WhatsAppButton = () => {
  // URL to open WhatsApp with a pre-filled message

  const phoneNumber = "+923275025277";
  const message = "Zong Games Unsubscribe";

  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  return (
    <div className="whatsapp-button-container">
      <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
        <button className="whatsapp-button">
          <img
            src={whatsappicon} // Replace with your WhatsApp icon
            alt="WhatsApp"
            className="whatsapp-icon"
          />
          Message Us
        </button>
      </a>
    </div>
  );
};

export default WhatsAppButton;
