import React from "react";
import HomeHead from "./HomeHead";
import HomeBody from "./HomeBody";
import HomeFooter from "./HomeFooter";
import Spinner from "../components/Spinner";
import useLoading from "../components/CustomHook";
import WhatsAppButton from "../CustomerSupport/WhatsAppButton";

const Home = () => {
  const loading = useLoading();
  return (
    <div className={`App ${loading ? "loading" : ""}`}>
      {loading && <Spinner />}
      <html lang="en">
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
          <meta name="forntEnd-Developer" content="Saad & Muzammil" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <title>GAMEZ</title>
          <link
            rel="shortcut icon"
            href="assets/images/favicon.png"
            type="image/x-icon"
          />
          <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
          <link rel="stylesheet" href="assets/css/plugin.css" />
          <link rel="stylesheet" href="assets/css/style.css" />
          <link rel="stylesheet" href="assets/css/responsive.css" />
          <link
            rel="preload"
            fetchpriority="high"
            as="image"
            href="./assets/images/text.webp"
            type="image/webp"
          />
        </head>
        <body>
          <HomeHead />
          <HomeBody />
          <HomeFooter />
          <WhatsAppButton />
        </body>
      </html>
    </div>
  );
};

export default Home;
