import React from 'react';
import './Spinner.css';

const Spinner = () => {
    return (
        <div className="main-spin">
        {/* <div className='logo-loader'> */}
            {/* <img src="/assets/images/loader.webp" alt="gif" className='gif-game-now'/> */}
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        {/* </div> */}
        </div>

    );
}

export default Spinner;
