import React from 'react'

const games = [
    {id: 1, thumbnail: 'Candy-Match-3 (1).webp',name: 'Candy Match 3', url: '083.Smiles%20Match%203/' },
    {id: 2, thumbnail: 'Balloon-Paradise.webp',name: 'Balloon Paradise', url: '054.Balloon%20Paradise/' },
    {id: 3, thumbnail: 'Casino-CardsMemory.webp',name: 'Casino Cards', url: '026.Casino%20Cards%20Memory/' },
    {id: 4, thumbnail: 'Christmas-Balls.webp',name: 'Christmas Balls', url: '105.Christmas%20Balls/' },
    {id: 5, thumbnail: 'Christmas-Bubbles.webp',name: 'Christmas Bubbles', url: '103.Christmas%20Bubbles/' },
    {id: 6, thumbnail: 'Candy_Super_Lines.webp', name: 'Candy Super Lines', url: '051.Super%20Color%20Lines%20Match%205/' },
    {id: 7, thumbnail: 'Billiards.webp',name: 'Billiards', url: '089.Billiards/' },
    {id: 8, thumbnail: 'Blocks-Super-Match-3.webp',name: 'Blocks Super', url: '075.Blocks%20Super%20Match%203/' },
    {id: 9,thumbnail: 'Candy-Match-3.webp',name: 'Candy Match ', url: '050.Candy%20Match%203/' },
    {id: 10,thumbnail: 'Animals-Crash-Match-3.webp',name: 'Animals Crash', url: '086.Animals%20Crash%20Match%203/' },
    {id: 11,thumbnail: 'Car-Physics.webp',name: 'Car Physics', url: '063.Car%20Physics/' },
    {id: 12,thumbnail: 'Cars.webp',name: 'Cars', url: '091.Cars/' },
    {id: 13, thumbnail: 'Christmas-Candy.webp',name: 'Christmas Candy', url: '079.Christmas%20Candy/' },
    {id: 14, thumbnail: 'Christmas-Gifts.webp',name: 'Christmas Gifts', url: '119.Christmas%20Candy/' },
    {id: 15, thumbnail: 'Christmas-Match-3.webp',name: 'Christmas Match', url: '020.Viking%20Escape/' },
    {id: 16, thumbnail: 'Christmas_Balloons.webp',name: 'Christmas Balloons', url: '022.Christmas%20Balloons/' },
    {id: 17, thumbnail: 'Christmas_Match_3.webp',name: 'Christmas Match 2', url: '017.Christmas%20Match%203/' },
    {id: 18, thumbnail: 'Christmas_Panda_Run.webp',name: 'Christmas Panda', url: '040.Christmas%20Panda%20Run/' },
    {id: 19, thumbnail: 'Classic-Match-3.webp',name: 'Classic Match', url: '073.Classic%20Match%203/' },
    {id: 20, thumbnail: 'Crazy-Car.webp',name: 'Crazy Car', url: '093.Sweet%20Match%203/' },
    {id: 21, thumbnail: 'Crazy-Match-3.webp',name: 'Crazy Match', url: '075.Blocks%20Super%20Match%203/' },
    {id: 22, thumbnail: 'Crazy-Runner.webp',name: 'Crazy Runner', url: '030.Crazy%20Runner/' },
    {id: 23, thumbnail: 'Duck_Shooter.webp',name: 'Duck Shooter', url: '033.Duck%20Shooter/' },
    { id: 24,thumbnail: 'Easter-Memory.webp',name: 'Easter Memory', url: '070.Easter%20Memory/' },
    { id: 25, thumbnail: 'Fantasy-Forest.webp',name: 'Fantasy Forest', url: '122.Fantasy%20Forest/' },
    {id: 26, thumbnail: 'Fashion-Dress-Up.webp',name: 'Fashion Dress Up', url: '124.Fashion%20Dress%20Up/' },
    {id: 27, thumbnail: 'Flappy-Ball.webp',name: 'Flappy Ball', url: '067.Flappy%20Ball/' },
    {id: 28, thumbnail: 'Flappy-Bounce.webp',name: 'Flappy Bounce', url: '068.Flappy%20Bounce/' },
    {id: 29, thumbnail: 'Frog-Super-Bubbles.webp',name: 'Frog Super Bubbles', url: '110.Frog%20Super%20Bubbles/' },
    {id: 30, thumbnail: 'Funny-Animals-Match-3.webp',name: 'Funny Animals', url: '072.Funny%20Animals%20Match%203/' },
    {id: 31, thumbnail: 'Funny-Faces-Match-3.webp',name: 'Funny Faces', url: '097.Funny%20Faces%20Match%203/' },
    {id: 32, thumbnail: 'Gold_Miner.webp',name: 'Gold Miner', url: '093.Sweet%20Match%203/' },
    {id: 33, thumbnail: 'Goof-Runner.webp',name: 'Goof Runner', url: '101.Goof%20Runner/' },
    {id: 34, thumbnail: 'Great_Air_Battles.webp',name: 'Great Air Battles', url: '045.Great%20Air%20Battles/' },
    {id: 35, thumbnail: 'Halloween-Memory.webp',name: 'Halloween Memory', url: '015.Halloween%20Memory/' },
    {id: 36, thumbnail: 'Happy-Halloween-Match-3.webp',name: 'Happy Halloween', url: '009.Happy%20Halloween/' },
    {id: 37, thumbnail: 'Happy_Halloween.webp',name: 'Happy Halloween', url: '003.Gold%20Miner%20Jack/' },
    {id: 38, thumbnail: 'Hot-Jewels.webp',name: 'Hot Jewels', url: '055.Hot%20Jewels/' },
    {id: 39, thumbnail: 'Jelly_Match 3.webp',name: 'Jelly Match 3', url: '057.Math%20Game%20For%20Kids/' },
    {id: 40, thumbnail: 'Jewels-Match.webp',name: 'Jewels Match', url: '107.Jewels%20Match/' },
    {id: 41, thumbnail: 'Kingdoms-Wars.webp',name: 'Kingdoms Wars', url: '125.Kingdoms%20Wars/' },
    {id: 42, thumbnail: 'Lollipops-Match-3.webp',name: 'Lollipops Match', url: '080.Lollipops%20Match%203/' },
    {id: 43, thumbnail: 'Mad_Shark.webp',name: 'Mad Shark', url: '046.Mad%20Shark/' },
    {id: 44, thumbnail: 'Memory-Game.webp',name: 'Memory Game', url: '065.Memory%20Game/' },
    {id: 45, thumbnail: 'Mini-Racer.webp',name: 'Mini Racer', url: '109.Mini%20Racer/' },
    {id: 46, thumbnail: 'Monster-Match-3.webp',name: 'Monster Match', url: '092.Monster%20Match%203/' },
    {id: 47, thumbnail: 'Ninja-Run.webp',name: 'Ninja Run', url: '049.Ninja%20Run/' },
    {id: 48, thumbnail: 'Ninja-Run-New.webp',name: 'Ninja Run New', url: '025.Ninja%20Run%20New/' },
    {id: 49, thumbnail: 'Plumber.webp',name: 'Plumber', url: '018.Plumber/' },
    {id: 50, thumbnail: 'Quick_Dice.webp',name: 'Quick Dice', url: '053.Quick%20Dice/' },
    {id: 51, thumbnail: 'Samurai-Run.webp',name: 'Samurai Run', url: '112.Samurai%20Run/' },
    {id: 52, thumbnail: 'Scary-Run.webp',name: 'Scary Run', url: '088.Scary%20Run/' },
    {id: 53, thumbnail: 'Schitalochka.webp',name: 'Schitalochka', url: '062.Schitalochka/' },
    {id: 54, thumbnail: 'Sea-Animals.webp',name: 'Sea Animals', url: '078.Sea%20Animals/' },
    {id: 55, thumbnail: 'Shoot_Robbers.webp',name: 'Shoot Robbers', url: '108.Pops%20Billiards/' },
    {id: 56, thumbnail: 'Smiles-Match-3.webp',name: 'Smiles  Match', url: '083.Smiles%20Match%203/' },
    {id: 57, thumbnail: 'Space-Match-3.webp',name: 'Space Match 3', url: '098.Space%20Match%203/' },
    {id: 58, thumbnail: 'Space_Purge.webp',name: 'Space Purge', url: '031.Space%20Purge/' },
    {id: 59, thumbnail: 'Speed-Racer.webp',name: 'Speed Racer', url: '081.Speed%20Racer/' },
    {id: 60, thumbnail: 'Splishy-Fish.webp',name: 'Splishy Fish', url: '071.Splishy%20Fish/' },
    {id: 61, thumbnail: 'Stack-Jump.webp',name: 'Stack Jump', url: '006.Stack%20Jump/' },
    {id: 62, thumbnail: 'Stick-Panda.webp',name: 'Stick Panda', url: '104.Stick%20Panda/' },
    {id: 63, thumbnail: 'Stick-Soldier.webp',name: 'Stick Soldier', url: '104.Stick%20Panda/' },
    {id: 64, thumbnail: 'Summer-Match-3.webp',name: 'Summer Match', url: '095.Summer%20Match%203/' },
    {id: 65, thumbnail: 'Super-Color-Lines-Match-5.webp',name: 'Super Color Lines', url: '051.Super%20Color%20Lines%20Match%205/' },
    {id: 66, thumbnail: 'Sweet-Match-3.webp',name: 'Sweet Match', url: '093.Sweet%20Match%203/' },
    {id: 67, thumbnail: 'Touch-Ball.webp',name: 'Touch Ball', url: '052.Touch%20Ball/' },
    {id: 68, thumbnail: 'Tractor-Mania.webp',name: 'Tractor Mania', url: '113.Tractor%20Mania/' },
    {id: 69, thumbnail: 'Traffic.webp',name: 'Traffic', url: '035.Sahara%20Invasion/' },
    {id: 70, thumbnail: 'Traffic-Racer.webp',name: 'Traffic Racer', url: '120.Traffic%20Racer/' },
    {id: 71, thumbnail: 'Truck-Racer.webp',name: 'Truck Racer', url: '100.Truck%20Racer/' },
    {id: 72, thumbnail: 'Valentines-Match-3.webp',name: 'Valentines Match', url: '084.Valentines%20Match%203/' },
    {id: 73, thumbnail: 'Viking-Escape.webp',name: 'Viking Escape', url: '020.Viking%20Escape/' },
    {id: 74, thumbnail: 'Zombie-Uprising.webp',name: 'Zombie Uprising', url: '088.Scary%20Run/' },
    {id: 75, thumbnail: 'Zombie_Buster.webp',name: 'Zombie Buster', url: '023.Zombie%20Buster/' },
    {id: 76, thumbnail: 'Zombie_Shooter.webp',name: 'Zombie Shooter', url: '039.Zombie%20Shooter/' },
    {id: 77, thumbnail: 'Brick-Breaker-Unicorn.webp',name: 'Brick Breaker', url: '111.Brick%20Breaker%20Unicorn/' },
    {id: 78, thumbnail: 'Cartoon_Candy.webp',name: 'Cartoon Candy', url: '066.Cartoon%20Candy/' },
    {id: 79, thumbnail: 'Crossword-For-Kids.webp',name: 'Crossword', url: '061.Crossword%20For%20Kids/' },
    {id: 80, thumbnail: 'Duck_Hunter.webp',name: 'Duck Hunter', url: '011.Duck%20Hunter/' },
    {id: 81, thumbnail: 'Fish-World-Match-3.webp',name: 'Fish World', url: '069.Fish%20World%20Match%203/' },
    {id: 82, thumbnail: 'Fishing_Frenzy.webp',name: 'Fishing Frenzy', url: '029.Fishing%20Frenzy/' },
    {id: 83, thumbnail: 'Forest-Brothers.webp',name: 'Forest Brothers', url: '126.%20Forest%20Brothers/' },
    {id: 84, thumbnail: 'Fruit_Snake.webp',name: 'Fruit Snake', url: '027.Fruit%20Snake/' },
    {id: 85, thumbnail: 'gold_miner_jack.webp',name: 'gold miner', url: '003.Gold%20Miner%20Jack/' },
    {id: 86, thumbnail: 'Halloween-Match-3.webp',name: 'Halloween Match 3', url: '087.Halloween%20Match%203/' },
    {id: 87, thumbnail: 'Halloween_Bubble_Shooter.webp',name: 'Halloween Bubble Shooter', url: '024.Halloween%20Bubble%20Shooter/' },
    {id: 88, thumbnail: 'handless_millionaire.webp',name: 'handless millionaire', url: '004.Handless%20Millionaire/' },
    {id: 89, thumbnail: 'Happy-Chef-Bubble-Shooter.webp',name: 'Happy Chef', url: '077.Happy%20Chef%20Bubble%20Shooter/' },
    {id: 90, thumbnail: 'Hit-Em-Up.webp',name: 'Hit Em Up', url: '116.Hit%20Em%20Up/' },
    {id: 91, thumbnail: 'Pops-Billiards.webp',name: 'Pops Billiards', url: '108.Pops%20Billiards/' },
    {id: 92, thumbnail: 'Pro-Billiards.webp',name: 'Pro Billiards', url: '121.Pro%20Billiards/' },
    {id: 93, thumbnail: 'Professor-Bubble.webp',name: 'Professor Bubble', url: '048.Professor%20Bubble/' },
    {id: 94, thumbnail: 'Jetpack-Heroes.webp',name: 'Jetpack Heroes', url: '059.Super%20PonGoal/' },
    {id: 95, thumbnail: 'Mad-Fish.webp',name: 'Mad Fish', url: '114.Mad%20Fish/' },
    {id: 96, thumbnail: 'Road-Racer.webp',name: 'Road Racer', url: '106.Road%20Racer/' },
    {id: 97, thumbnail: 'Robotex.webp',name: 'Robotex', url: '064.Robotex/' },
    {id: 98, thumbnail: 'Sahara Invasion.webp',name: 'Sahara Invasion', url: '035.Sahara%20Invasion/' },
    {id: 99, thumbnail: 'Smiles.webp',name: 'Smiles', url: '056.Smiles/' },
    {id: 100, thumbnail: 'Super-PonGoal.webp',name: 'Super PonGoal', url: '059.Super%20PonGoal/' },
    {id: 101, thumbnail: 'Supercars-Puzzle.webp',name: 'Supercars Puzzle', url: '060.Supercars%20Puzzle/' },
    {id: 102, thumbnail: 'Virus-Crasher.webp',name: 'Virus Crasher', url: '123.Virus%20Crasher/' },
    {id: 103, thumbnail: 'Warriors-VS-Evil-Spirits.webp',name: 'Warriors VS Evil', url: '117.Warriors%20VS%20Evil%20Spirits/' },
    {id: 104, thumbnail: '590_Donut_Crash_Saga.webp',name: 'Donut Crash Saga', url: '115.Donut%20Crash%20Saga/' }
];

const AllGames = () => {
  
  return (
    <>
    
    </>
  )
}
export  { games };
export default AllGames